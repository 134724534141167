<template>
  <div>
    <el-dialog
      title="修改短信"
      width="50%"
      top="5"
      :visible.sync="dialogTableVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="dialog">
        <div class="top">
          <div class="box" v-for="(item, index) in 100" :key="index">
            <el-radio v-model="radio2" label="1" border size="medium"
              >电话</el-radio
            >
          </div>
        </div>
        <div class="bottom">
          <el-input
            type="textarea"
            :autosize="{ minRows: 7, maxRows: 20 }"
            placeholder="请输入短信模板"
            @focus="onFocus"
          ></el-input>
        </div>
      </div>
      <div class="submit">
        <el-button type="info" size="small">取消</el-button>
        <el-button type="primary" size="small">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dialogTableVisible: false,
      radio1: false,
    };
  },
  mounted() {},
  methods: {
    onFocus() {},
  },
};
</script>

<style lang="less" scoped>
.dialog {
  max-height: 70vh;
  .top {
    min-height: 10vh;
    max-height: 50vh;
    overflow-y: scroll;
    display: flex;
    flex-wrap: wrap;
    .box {
      width: 50%;
      .el-radio {
        margin-bottom: 10px;
      }
    }
  }
  .top::-webkit-scrollbar-track {
    background-color: #ffffff;
  }
  .top::-webkit-scrollbar {
    width: 3px;
  }
  .top::-webkit-scrollbar-thumb {
    background-color: #999999;
  }
  .bottom {
    margin-top: 20px;
  }
}

.submit {
  display: flex;
  justify-content: end;
  margin-top: 20px;
}
</style>
